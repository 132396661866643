import { StatefulChatClient } from '@azure/communication-react';
import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit';
import { RootState } from 'store';

export interface IRelationshipThread {
  id: string;
  threadId: string;
  isConcluded: boolean;
  isUnreadMessages: boolean;
}

export interface IRelationshipThreads {
  threads: IRelationshipThread[];
  statefulChatClient: StatefulChatClient | undefined;
}

const initialState: IRelationshipThreads = {
  threads: [],
  statefulChatClient: undefined,
};

export const relationshipThreadsSlice: Slice<IRelationshipThreads> =
  createSlice({
    name: 'relationshipThreads',
    initialState,
    reducers: {
      setIsUnreadMessages: (
        state,
        action: PayloadAction<{ threadId: string }>,
      ) => {
        const threads = state.threads.map((thread) => {
          if (thread.threadId === action.payload.threadId) {
            return {
              ...thread,
              isUnreadMessages: true,
            };
          }
          return thread;
        });
        return {
          ...state,
          threads,
        };
      },
      unsetIsUnreadMessages: (
        state,
        action: PayloadAction<{ threadId: string }>,
      ) => {
        const threads = state.threads.map((thread) => {
          if (thread.threadId === action.payload.threadId) {
            return { ...thread, isUnreadMessages: false };
          }
          return thread;
        });
        return {
          ...state,
          threads,
        };
      },
      updateRelationshipThread: (
        state,
        action: PayloadAction<IRelationshipThread>,
      ) => {
        const threads = state.threads.map((thread) => {
          if (thread.id === action.payload.id) {
            return action.payload;
          }
          return thread;
        });
        return {
          ...state,
          threads,
        };
      },
      updateRelationshipThreads: (
        state,
        action: PayloadAction<IRelationshipThreads>,
      ) => {
        return action.payload;
      },
      updateThreads: (state, action: PayloadAction<IRelationshipThread[]>) => {
        return {
          ...state,
          threads: action.payload,
        };
      },
    },
  });

export const {
  setIsUnreadMessages,
  unsetIsUnreadMessages,
  updateRelationshipThread,
  updateRelationshipThreads,
  updateThreads,
} = relationshipThreadsSlice.actions;

export const selectRelationshipThreads = (state: RootState) =>
  state.relationshipThreads;

export const selectRelationshipThread =
  (threadId: string) => (state: RootState) => {
    const { threads, statefulChatClient } = state.relationshipThreads;
    const thread = threads.find((thread) => thread.threadId === threadId);

    return {
      thread,
      statefulChatClient,
    };
  };

export const selectRelationshipIdThread =
  (relationshipId: string) => (state: RootState) => {
    const threads = state.relationshipThreads.threads;
    const isThreads = threads.length > 0;

    const thread = isThreads
      ? threads.filter((thread) => thread.id === relationshipId)[0]
      : undefined;

    const statefulChatClient = state.relationshipThreads.statefulChatClient;

    return {
      thread,
      statefulChatClient,
    };
  };

export const selectIsUnreadMessagesThreadId =
  (threadId: string) => (state: RootState) => {
    const threads = state.relationshipThreads.threads;
    const isThreads = threads.length > 0;
    const thread = isThreads
      ? threads.filter((thread) => thread.threadId === threadId)[0]
      : undefined;
    return thread && thread.isUnreadMessages;
  };
export const selectIsUnreadMessages =
  (relationshipId: string) => (state: RootState) => {
    const threads = state.relationshipThreads.threads;
    const isThreads = threads.length > 0;
    const thread = isThreads
      ? threads.filter((thread) => thread.id === relationshipId)[0]
      : undefined;
    return thread && thread.isUnreadMessages;
  };

export default relationshipThreadsSlice.reducer;
